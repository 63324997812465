import { Images } from './Images'

export const FontFamily = {
  FontFamily_1: 'Nunito, sans-serif',
  FontFamily_2: 'Nunito, sans-serif',
};

export const BaseColor = {
  mainColor: '#367e88',
  blackColor: '#000',
  lightBlackColor: '#000000d1',
  grayColor: "#6d6e71",
  dividerColor: "#BDBDBD",
  ThirdButtonColor: '#0070e4',
  ThirdButtonHoverColor: '#1073c6',
  SecoundButtonColor: '#225151',
  SecoundButtonHoverColor: '#225151',
  fontgreendark: '#173a40',
  mainButtonColor: '#16416f',
  mainButtonHoverColor: '#16416f',
  bluetext: '#0F568C',
  whiteColor: "#FFFFFF",
  blackColor: "#000000",
  BlackTextColor: " #0f0f0f",
  fieldColor: "#F5F5F5",
  yellowColor: "#FDC60A",
  navyBlue: "#3C5A99",
  kashmir: "#5D6D7E",
  orangeColor: "#E5634D",
  blueColor: "#5DADE2",
  pinkColor: "#c6235a",
  grayLightColor: '#dfdfdf',
  greenColor: "#06b80a",
  pinkLightColor: "#FF5E80",
  pinkDarkColor: "#F90030",
  accentColor: "#4A90A4",
  redColor: '#FF0000',
  lightblueColor: '#c7dae9',
  lightredColor: '#f7e4e8',
  light_green_row: '#90EE90',
  orange_row: '#FFA500',
  indian_red_row: '#CD5C5C',
  light_blue_row: '#ADD8E6',
  inputBgColor: '#f5f7fa',
  inputBorderColor: "#d8dbe0",
  inputErrorColor: '#f44336',
  inputFontColor: '#646464',
  inputFocusBorderColor: '#d8dbe0',
  inputLableColor: '#6d6d6d',
  formcontrolBtnColor: '#cdcfd7',
  closeBtnColor: '#b73232',
  navLinkColor: '#6d6e71',
  navLinkHoverColor: "#367e88",
  navDropDownOptionHoverBg: '#f5f7fa',
  navDropDownborder: '#f5f7fa',
  cardlightbgcolor: '#f4f4f7',
  cardDarkbgcolor: '#e8e8e8',
  emaillinkcolor: '#2157e1',
  darkgreen: '#043b41',
  garycardcolor: '#ececec',
  skycolor: '#0079c2'
};
export const BaseImages = {
  footerImage: `url(${Images.FooterBG})`,
  nextImage: `url(${Images.NextGrayArrows})`,
  preImage: `url(${Images.BackGrayArrows})`,
}

