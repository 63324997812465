import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { BaseColor, BaseImages, FontFamily } from './source/config';
import {
  Announcements,
  ApiLogs,
  Banks,
  BlankPage,
  BugTracker,
  ByDate,
  ByMembers,
  Changepassword,
  CommunicationConfigurations,
  CommunicationTemplates,
  Communications,
  Dashboard,
  Designation,
  DocumentTypes,
  FormCantrol,
  HTMLEmailDemo,
  Holidays,
  Increments,
  LeaveTypes,
  Login,
  MixScreen,
  PrivacyPolicy,
  Rights, Roles,
  ScreenLayout,
  Screens,
  Setpassword,
  Summary,
  Teams,
  TenantDomains,
  TenantSMTP,
  Tenants,
  UserAllotedLeaves,
  UserLeaves,
  Userprofile,
  Users,
  Documents, SiteConfigurations,
  LoginLogs,
  EmailLogs,
} from "./source/config/PagePathList";
import { authService } from './source/config/appConstant';
import Notification from './source/components/Notification/Notification';

function App() {
  useEffect(() => {
    // Update CSS variables with JavaScript  // For Global Colors And Images Call From Api as well as appconfig file

    Object.entries(BaseImages).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
    Object.entries(FontFamily).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
    Object.entries(BaseColor).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
  }, []);

  const isLoggedIn = authService.checkLogin();

  if (isLoggedIn) {
    return (
      <div>
        <ScreenLayout>
          <Routes>
            <Route path="*" element={<Navigate replace to="/dashboard" />} />
            <Route path="/mixScreen" element={<MixScreen />} />
            <Route path="/blankpage" element={<BlankPage />} />
            <Route path="/formcantrol" element={<FormCantrol />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/HTMLEmailDemo" element={<HTMLEmailDemo />} />
            <Route path="/bugtracker" element={<BugTracker />} />
            <Route path="/ApiLogs" element={<ApiLogs />} />
            <Route path="/documenttypes" element={<DocumentTypes />} />
            <Route path="/acs/rights" element={<Rights />} />
            <Route path="/acs/roles" element={<Roles />} />
            <Route path="/acs/screens" element={<Screens />} />
            <Route path="/communications/communication" element={<Communications />} />
            <Route path="/communications/communicationtemplates" element={<CommunicationTemplates />} />
            <Route path="/communications/communicationconfigurations" element={<CommunicationConfigurations />} />
            <Route path="/tetant/tenantdomains" element={<TenantDomains />} />
            <Route path="/tetant/tenants" element={<Tenants />} />
            <Route path="/tetant/tenantsmtp" element={<TenantSMTP />} />
            <Route path="/tetant/siteconfigurations" element={<SiteConfigurations />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/Teams" element={<Teams />} />
            <Route path="/Designation" element={<Designation />} />
            <Route path="/Bank" element={<Banks />} />
            <Route path="/LeaveTypes" element={<LeaveTypes />} />
            <Route path="/Holidays" element={<Holidays />} />
            <Route path="/Announcements" element={<Announcements />} />
            <Route path="/Increments" element={<Increments />} />
            <Route path="/users" element={<Users />} />
            <Route path="/UserLeaves" element={<UserLeaves />} />
            <Route path="/UserAllotedLeaves" element={<UserAllotedLeaves />} />
            <Route path="/notification" element={<Communications />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/LoginLogs" element={<LoginLogs />} />
            <Route path="/tenant/EmailLogs" element={<EmailLogs />} />


            <Route path="/summary" element={<Summary />} />
            <Route path="/byMembers" element={<ByMembers />} />
            <Route path="/byDate" element={<ByDate />} />

          </Routes>
        </ScreenLayout>
      </div>
    )
  }

  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/setPassword" element={<Setpassword />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </div>
  );

}



export default App;
