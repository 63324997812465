

export function GetBaseURL() {
    var HostName = window.location.hostname
    var ReturnURL = '';
    if (HostName.indexOf("usosmconnect.com") >= 0) {  //Live
        ReturnURL = 'https://api.usosmconnect.com/'
    }
    else if (HostName.indexOf("bl.console.mybauschreach.com") >= 0) {  //Live
        ReturnURL = 'https://bl.api.mybauschreach.com/'
    }

    else if (HostName.indexOf("yomi.portal.thestaging.cc") >= 0) {  //Hostgator - Yomi
        ReturnURL = 'https://yomi.api.thestaging.cc/'
    }
    else {  //Local
        ReturnURL = 'https://erp.api.thestaging.cc/'
        // ReturnURL = 'http://bl.api.thestaging.cc/'
        // ReturnURL = 'http://192.168.14:91/'
        // ReturnURL = 'http://VMOBEE-SERVER:91/'

    }

    return ReturnURL
}

export const URLs = {
    base: GetBaseURL(),
    googlekey: 'AIzaSyAii_9SU6ec6sCpwyaHIurMBvktQ8fv6JI',//google API key
    AddEditDocument: "https://erp.api.thestaging.cc/" + "api/Master/AddEditDocument"

}