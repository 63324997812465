import { URLs } from './URLs'
import { API, HTTPMethod } from "../API/API";

export const Login = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + '/token', JSON.stringify(objParameter));
export const GetMySubscriptions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetMySubscriptions', JSON.stringify(objParameter));
export const GetNav = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/ACS/GetNav', JSON.stringify(objParameter));


// Admin Side start
//Users
// export const GetUsers = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUsers', JSON.stringify(objParameter));
// export const GetUserByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserByID', JSON.stringify(objParameter));
// export const DeleteUser = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/DeleteUser', JSON.stringify(objParameter));
// export const AddEditUser = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/AddEditUser', JSON.stringify(objParameter));
// export const GetUserForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserForDropdown', JSON.stringify(objParameter));

export const SetPassword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/SetPassword', JSON.stringify(objParameter));
export const ResetPassword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/ResetPassword', JSON.stringify(objParameter));
export const EditProfile = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/EditProfile', JSON.stringify(objParameter));
export const GetMyProfile = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetMyProfile', JSON.stringify(objParameter));


//ACS Start

//Rights
export const GetRights = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRights', JSON.stringify(objParameter));
export const GetRightForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRightForDropdown', JSON.stringify(objParameter));
export const GetRightByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRightByID', JSON.stringify(objParameter));
export const DeleteRight = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/DeleteRight', JSON.stringify(objParameter));
export const AddEditRight = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/AddEditRight', JSON.stringify(objParameter));

//Roles
export const GetRoles = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRoles', JSON.stringify(objParameter));
export const GetRoleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRoleForDropdown', JSON.stringify(objParameter));
export const GetRoleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRoleByID', JSON.stringify(objParameter));
export const DeleteRole = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/DeleteRole', JSON.stringify(objParameter));
export const AddEditRole = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/AddEditRole', JSON.stringify(objParameter));

//Screens
export const GetScreens = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetScreens', JSON.stringify(objParameter));
export const GetScreenForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetScreenForDropdown', JSON.stringify(objParameter));
export const GetScreenByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetScreenByID', JSON.stringify(objParameter));
export const DeleteScreen = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/DeleteScreen', JSON.stringify(objParameter));
export const AddEditScreen = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/AddEditScreen', JSON.stringify(objParameter));
export const ScreenForDropdownIsParent = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetScreenForDropdown', JSON.stringify(objParameter));

//ACS end

//Communication

export const GetCommunications = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Communication/GetCommunications', JSON.stringify(objParameter));
export const DownloadCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Communication/DownloadCommunicationTemplate', JSON.stringify(objParameter));
export const SetCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Communication/SetCommunicationTemplate', JSON.stringify(objParameter));

export const AddEditCommunicationConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/AddEditCommunicationConfiguration', JSON.stringify(objParameter));
export const DeleteCommunicationConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/DeleteCommunicationConfiguration', JSON.stringify(objParameter));
export const GetCommunicationConfigurationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationConfigurationByID', JSON.stringify(objParameter));
export const GetCommunicationConfigurations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationConfigurations', JSON.stringify(objParameter));
export const GetCommunicationConfigurationForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationConfigurationForDropdown', JSON.stringify(objParameter));

//CommunicationTemplates
export const GetCommunicationTemplates = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationTemplates', JSON.stringify(objParameter));
export const AddEditCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/AddEditCommunicationTemplate', JSON.stringify(objParameter));
export const DeleteCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/DeleteCommunicationTemplate', JSON.stringify(objParameter));
export const GetCommunicationTemplateByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationTemplateByID', JSON.stringify(objParameter));
export const GetCommunicationTemplateForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationTemplateForDropdown', JSON.stringify(objParameter));

//Tenants
export const AddEditTenantConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Tenant/AddEditTenantConfiguration', JSON.stringify(objParameter));
export const GetTenantConfigurationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Tenant/GetTenantConfigurationByID', JSON.stringify(objParameter));
//Tenants
export const GetTenants = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenants', JSON.stringify(objParameter));
export const AddEditTenant = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/AddEditTenant', JSON.stringify(objParameter));
export const DeleteTenant = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/DeleteTenant', JSON.stringify(objParameter));
export const GetTenantByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantByID', JSON.stringify(objParameter));
export const GetTenantForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantForDropdown', JSON.stringify(objParameter));

//TenantsDomain
export const GetTenantDomains = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantDomains', JSON.stringify(objParameter));
export const AddEditTenantDomain = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/AddEditTenantDomain', JSON.stringify(objParameter));
export const DeleteTenantDomain = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/DeleteTenantDomain', JSON.stringify(objParameter));
export const GetTenantDomainByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantDomainByID', JSON.stringify(objParameter));
export const GetTenantDomainForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantDomainForDropdown', JSON.stringify(objParameter));


//TenantSMTP
export const GetTenantSMTPForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantSMTPForDropdown', JSON.stringify(objParameter));
export const GetTenantSMTPByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantSMTPByID', JSON.stringify(objParameter));
export const GetTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantSMTP', JSON.stringify(objParameter));
export const DeleteTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/DeleteTenantSMTP', JSON.stringify(objParameter));
export const AddEditTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/AddEditTenantSMTP', JSON.stringify(objParameter));




















//Country
export const AddEditCountry = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditCountry', JSON.stringify(objParameter));
export const DeleteCountry = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteCountry', JSON.stringify(objParameter));
export const GetCountryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetCountryByID', JSON.stringify(objParameter));
export const GetCountries = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetCountries', JSON.stringify(objParameter));
export const GetCountryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetCountryForDropdown', JSON.stringify(objParameter));


//State
export const GetStates = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetStates', JSON.stringify(objParameter));
export const GetStateForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetStateForDropdown', JSON.stringify(objParameter));
export const GetStateByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetStateByID', JSON.stringify(objParameter));
export const DeleteState = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteState', JSON.stringify(objParameter));
export const AddEditState = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditState', JSON.stringify(objParameter));


//Areas
export const GetAreas = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetAreas', JSON.stringify(objParameter));
export const GetAreaForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetAreaForDropdown', JSON.stringify(objParameter));
export const GetAreaByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetAreaByID', JSON.stringify(objParameter));
export const DeleteArea = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteArea', JSON.stringify(objParameter));
export const AddEditArea = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditArea', JSON.stringify(objParameter));


//Categories
export const GetCategoryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetCategoryForDropdown', JSON.stringify(objParameter));
export const GetCategoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetCategoryByID', JSON.stringify(objParameter));
export const GetCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetCategories', JSON.stringify(objParameter));
export const DeleteCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteCategory', JSON.stringify(objParameter));
export const AddEditCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditCategory', JSON.stringify(objParameter));


//Cities
export const GetCityForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetCityForDropdown', JSON.stringify(objParameter));
export const GetCityByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetCityByID', JSON.stringify(objParameter));
export const GetCities = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetCities', JSON.stringify(objParameter));
export const DeleteCity = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteCity', JSON.stringify(objParameter));
export const AddEditCity = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditCity', JSON.stringify(objParameter));


//Departments
export const GetDepartments = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDepartments', JSON.stringify(objParameter));
export const GetDepartmentForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDepartmentForDropdown', JSON.stringify(objParameter));
export const GetDepartmentByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDepartmentByID', JSON.stringify(objParameter));
export const DeleteDepartment = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteDepartment', JSON.stringify(objParameter));
export const AddEditDepartment = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditDepartment', JSON.stringify(objParameter));

//DocumentTypes
export const GetDocumentTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDocumentTypes', JSON.stringify(objParameter));
export const GetDocumentTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDocumentTypeForDropdown', JSON.stringify(objParameter));
export const GetDocumentTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDocumentTypeByID', JSON.stringify(objParameter));
export const DeleteDocumentType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteDocumentType', JSON.stringify(objParameter));
export const AddEditDocumentType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditDocumentType', JSON.stringify(objParameter));



//Memberships
export const GetMemberships = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetMemberships', JSON.stringify(objParameter));
export const GetMembershipForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetMembershipForDropdown', JSON.stringify(objParameter));
export const GetMembershipByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetMembershipByID', JSON.stringify(objParameter));
export const DeleteMembership = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteMembership', JSON.stringify(objParameter));
export const AddEditMembership = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditMembership', JSON.stringify(objParameter));


//Positions
export const GetPositions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPositions', JSON.stringify(objParameter));
export const GetPositionForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPositionForDropdown', JSON.stringify(objParameter));
export const GetPositionByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPositionByID', JSON.stringify(objParameter));
export const DeletePosition = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeletePosition', JSON.stringify(objParameter));
export const AddEditPosition = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditPosition', JSON.stringify(objParameter));


//PostageCharges
export const GetPostageCharges = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPostageCharges', JSON.stringify(objParameter));
export const GetPostageChargeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPostageChargeForDropdown', JSON.stringify(objParameter));
export const GetPostageChargeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPostageChargeByID', JSON.stringify(objParameter));
export const DeletePostageCharge = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeletePostageCharge', JSON.stringify(objParameter));
export const AddEditPostageCharge = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditPostageCharge', JSON.stringify(objParameter));


//Segments
export const GetSegments = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetSegments', JSON.stringify(objParameter));
export const GetSegmentForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetSegmentForDropdown', JSON.stringify(objParameter));
export const GetSegmentByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetSegmentByID', JSON.stringify(objParameter));
export const DeleteSegment = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteSegment', JSON.stringify(objParameter));
export const AddEditSegment = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditSegment', JSON.stringify(objParameter));


//UserTypes
export const GetUserTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetUserTypes', JSON.stringify(objParameter));
export const GetUserTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetUserTypeForDropdown', JSON.stringify(objParameter));
export const GetUserTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetUserTypeByID', JSON.stringify(objParameter));
export const DeleteUserType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteUserType', JSON.stringify(objParameter));
export const AddEditUserType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditUserType', JSON.stringify(objParameter));

//Regions
export const GetRegions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetRegions', JSON.stringify(objParameter));
export const GetRegionForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetRegionForDropdown', JSON.stringify(objParameter));
export const GetRegionByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetRegionByID', JSON.stringify(objParameter));
export const DeleteRegion = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteRegion', JSON.stringify(objParameter));
export const AddEditRegion = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditRegion', JSON.stringify(objParameter));

//Territory
export const GetTerritoryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTerritoryForDropdown', JSON.stringify(objParameter));
export const GetTerritoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTerritoryByID', JSON.stringify(objParameter));
export const GetTerritories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTerritories', JSON.stringify(objParameter));
export const DeleteTerritory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteTerritory', JSON.stringify(objParameter));
export const AddEditTerritory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditTerritory', JSON.stringify(objParameter));


//Zipcodes
export const GetZipcodes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetZipcodes', JSON.stringify(objParameter));
export const GetZipcodeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetZipcodeForDropdown', JSON.stringify(objParameter));
export const GetZipcodeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetZipcodeByID', JSON.stringify(objParameter));
export const DeleteZipcode = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteZipcode', JSON.stringify(objParameter));
export const AddEditZipcode = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditZipcode', JSON.stringify(objParameter));

//GlobalModules
export const GetGlobalModules = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalModules', JSON.stringify(objParameter));
export const GetGlobalModuleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalModuleForDropdown', JSON.stringify(objParameter));
export const GetGlobalModuleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalModuleByID', JSON.stringify(objParameter));
export const DeleteGlobalModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/DeleteGlobalModule', JSON.stringify(objParameter));
export const AddEditGlobalModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/AddEditGlobalModule', JSON.stringify(objParameter));


//GlobalSubModules
export const GetGlobalSubModules = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalSubModules', JSON.stringify(objParameter));
export const GetGlobalSubModuleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalSubModuleForDropdown', JSON.stringify(objParameter));
export const GetGlobalSubModuleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalSubModuleByID', JSON.stringify(objParameter));
export const DeleteGlobalSubModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/DeleteGlobalSubModule', JSON.stringify(objParameter));
export const AddEditGlobalSubModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/AddEditGlobalSubModule', JSON.stringify(objParameter));


//GlobalAssetTypes
export const GetGlobalAssetTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalAssetTypes', JSON.stringify(objParameter));
export const GetGlobalAssetTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalAssetTypeForDropdown', JSON.stringify(objParameter));
export const GetGlobalAssetTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalAssetTypeByID', JSON.stringify(objParameter));
export const DeleteGlobalAssetType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/DeleteGlobalAssetType', JSON.stringify(objParameter));
export const AddEditGlobalAssetType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/AddEditGlobalAssetType', JSON.stringify(objParameter));

//GlobalAssetSizes
export const GetGlobalAssetSizes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalAssetSizes', JSON.stringify(objParameter));
export const GetGlobalAssetSizeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalAssetSizeForDropdown', JSON.stringify(objParameter));
export const GetGlobalAssetSizeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalAssetSizeByID', JSON.stringify(objParameter));
export const DeleteGlobalAssetSize = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/DeleteGlobalAssetSize', JSON.stringify(objParameter));
export const AddEditGlobalAssetSize = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/AddEditGlobalAssetSize', JSON.stringify(objParameter));

//Modules
export const GetModules = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetModules', JSON.stringify(objParameter));
export const GetModuleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetModuleForDropdown', JSON.stringify(objParameter));
export const GetModuleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetModuleByID', JSON.stringify(objParameter));
export const DeleteModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/DeleteModule', JSON.stringify(objParameter));
export const AddEditModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/AddEditModule', JSON.stringify(objParameter));

//SubModules
export const GetSubModules = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetSubModules', JSON.stringify(objParameter));
export const GetSubModuleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetSubModuleForDropdown', JSON.stringify(objParameter));
export const GetSubModuleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetSubModuleByID', JSON.stringify(objParameter));
export const DeleteSubModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/DeleteSubModule', JSON.stringify(objParameter));
export const AddEditSubModule = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/AddEditSubModule', JSON.stringify(objParameter));

//AssetTypes
export const GetAssetTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetAssetTypes', JSON.stringify(objParameter));
export const GetAssetTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetAssetTypeForDropdown', JSON.stringify(objParameter));
export const GetAssetTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetAssetTypeByID', JSON.stringify(objParameter));
export const DeleteAssetType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/DeleteAssetType', JSON.stringify(objParameter));
export const AddEditAssetType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/AddEditAssetType', JSON.stringify(objParameter));

//AssetSizes
export const GetAssetSizes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetAssetSizes', JSON.stringify(objParameter));
export const GetAssetSizeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetAssetSizeForDropdown', JSON.stringify(objParameter));
export const GetAssetSizeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetAssetSizeByID', JSON.stringify(objParameter));
export const DeleteAssetSize = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/DeleteAssetSize', JSON.stringify(objParameter));
export const AddEditAssetSize = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/AddEditAssetSize', JSON.stringify(objParameter));


//Product
export const GetProducts = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProducts', JSON.stringify(objParameter));
export const GetProductForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductForDropdown', JSON.stringify(objParameter));
export const GetProductByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductByID', JSON.stringify(objParameter));
export const DeleteProduct = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/DeleteProduct', JSON.stringify(objParameter));
export const AddEditProduct = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/AddEditProduct', JSON.stringify(objParameter));

export const GetProductTabs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Asset/GetProductTabs', JSON.stringify(objParameter));


//ProductCategories
export const GetProductCategoryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductCategoryForDropdown', JSON.stringify(objParameter));
export const GetProductCategoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductCategoryByID', JSON.stringify(objParameter));
export const GetProductCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductCategories', JSON.stringify(objParameter));
export const DeleteProductCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/DeleteProductCategory', JSON.stringify(objParameter));
export const AddEditProductCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/AddEditProductCategory', JSON.stringify(objParameter));


//ProductCategorySubCategories
export const GetProductCategorySubCategoryForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductCategorySubCategoryForDropdown', JSON.stringify(objParameter));
export const GetProductCategorySubCategoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductCategorySubCategoryByID', JSON.stringify(objParameter));
export const GetProductCategorySubCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductCategorySubCategories', JSON.stringify(objParameter));
export const DeleteProductCategorySubCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/DeleteProductCategorySubCategory', JSON.stringify(objParameter));
export const AddEditProductCategorySubCategory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/AddEditProductCategorySubCategory', JSON.stringify(objParameter));

//globalfilters

export const GetGlobalFilters = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalFilters', JSON.stringify(objParameter));
export const GetGlobalFilterForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalFilterForDropdown', JSON.stringify(objParameter));
export const GetGlobalFilterByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalFilterByID', JSON.stringify(objParameter));
export const DeleteGlobalFilter = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/DeleteGlobalFilter', JSON.stringify(objParameter));
export const AddEditGlobalFilter = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/AddEditGlobalFilter', JSON.stringify(objParameter));

//Filter
export const GetProductFilterForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductFilterForDropdown', JSON.stringify(objParameter));

export const GetFilters = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetFilters', JSON.stringify(objParameter));
export const GetFilterForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetFilterForDropdown', JSON.stringify(objParameter));
export const GetFilterByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/GetFilterByID', JSON.stringify(objParameter));
export const DeleteFilter = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/DeleteFilter', JSON.stringify(objParameter));
export const AddEditFilter = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Local/AddEditFilter', JSON.stringify(objParameter));

//ProductFiltersDetails
export const GetProductFilterDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductFilterDetails', JSON.stringify(objParameter));
export const GetProductFilterDetailForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductFilterDetailForDropdown', JSON.stringify(objParameter));
export const GetProductFilterDetailByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductFilterDetailByID', JSON.stringify(objParameter));
export const DeleteProductFilterDetail = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/DeleteProductFilterDetail', JSON.stringify(objParameter));
export const AddEditProductFilterDetail = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/AddEditProductFilterDetail', JSON.stringify(objParameter));


//BL_ST_LensTypes
export const GetBL_ST_LensTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/GetBL_ST_LensTypes', JSON.stringify(objParameter));
export const GetBL_ST_LensTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/GetBL_ST_LensTypeForDropdown', JSON.stringify(objParameter));
export const GetBL_ST_LensTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/GetBL_ST_LensTypeByID', JSON.stringify(objParameter));
export const DeleteBL_ST_LensType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/DeleteBL_ST_LensType', JSON.stringify(objParameter));
export const AddEditBL_ST_LensType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/AddEditBL_ST_LensType', JSON.stringify(objParameter));


//BL_ST_LocationPricing
export const GetBL_ST_LocationPricingForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/GetBL_ST_LocationPricingForDropdown', JSON.stringify(objParameter));
export const GetBL_ST_LocationPricingByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/GetBL_ST_LocationPricingByID', JSON.stringify(objParameter));
export const GetBL_ST_LocationPricing = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/GetBL_ST_LocationPricing', JSON.stringify(objParameter));
export const DeleteBL_ST_LocationPricing = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/DeleteBL_ST_LocationPricing', JSON.stringify(objParameter));
export const AddEditBL_ST_LocationPricing = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/AddEditBL_ST_LocationPricing', JSON.stringify(objParameter));


//BL_ST_Configurations
export const GetBL_ST_Configurations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/GetBL_ST_Configurations', JSON.stringify(objParameter));
export const GetBL_ST_ConfigurationForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/GetBL_ST_ConfigurationForDropdown', JSON.stringify(objParameter));
export const GetBL_ST_ConfigurationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/GetBL_ST_ConfigurationByID', JSON.stringify(objParameter));
export const DeleteBL_ST_Configuration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/DeleteBL_ST_Configuration', JSON.stringify(objParameter));
export const AddEditBL_ST_Configuration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/AddEditBL_ST_Configuration', JSON.stringify(objParameter));

//BL_ST_LocationPricingHistory
export const GetBL_ST_LocationPricingHistory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BL_ST/GetBL_ST_LocationPricingHistory', JSON.stringify(objParameter));


//ProductAssets
export const GetProductAssetByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/GetProductAssetByID', JSON.stringify(objParameter));
export const AddEditProductAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Asset/AddEditProductAsset', JSON.stringify(objParameter));
export const DeleteProductAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Asset/DeleteProductAsset', JSON.stringify(objParameter));

//ProductAssetsForUploading
export const GetProductAssetsForUploading = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Asset/GetProductAssetsForUploading', JSON.stringify(objParameter));


//UserLoginLogs
export const GetUserLoginLogs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserLoginLogs', JSON.stringify(objParameter));

//UserTerritories
export const GetUserTerritoryByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserTerritoryByID', JSON.stringify(objParameter));
export const GetUserTerritories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserTerritories', JSON.stringify(objParameter));
export const DeleteUserTerritory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/DeleteUserTerritory', JSON.stringify(objParameter));
export const AddEditUserTerritory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/AddEditUserTerritory', JSON.stringify(objParameter));

//Locations

export const GetLocations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetLocations', JSON.stringify(objParameter));
export const GetLocationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetLocationByID', JSON.stringify(objParameter));
export const DeleteLocation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/DeleteLocation', JSON.stringify(objParameter));
export const AddEditLocation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/AddEditLocation', JSON.stringify(objParameter));

//LocationMemberships
export const GetLocationMemberships = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetLocationMemberships', JSON.stringify(objParameter));
export const GetLocationMembershipByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetLocationMembershipByID', JSON.stringify(objParameter));
export const DeleteLocationMembership = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/DeleteLocationMembership', JSON.stringify(objParameter));
export const AddEditLocationMembership = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/AddEditLocationMembership', JSON.stringify(objParameter));






//LPTemplates
export const GetLPTemplates = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LP/GetLPTemplates', JSON.stringify(objParameter));
export const GetLPTemplateByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LP/GetLPTemplateByID', JSON.stringify(objParameter));
export const DeleteLPTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LP/DeleteLPTemplate', JSON.stringify(objParameter));
export const AddEditLPTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LP/AddEditLPTemplate', JSON.stringify(objParameter));
export const GenerateLandingPages = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LP/GenerateLandingPages', JSON.stringify(objParameter));

//LPReports
export const GetLPReports = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LP/GetLPReports', JSON.stringify(objParameter));
export const DeleteLPReport = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LP/DeleteLPReport', JSON.stringify(objParameter));
export const AddEditLPReport = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LP/AddEditLPReport', JSON.stringify(objParameter));
export const ImportAnalytics = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LP/ImportAnalytics', JSON.stringify(objParameter));

//LPReportDetails
export const GetLPReportDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LP/GetLPReportDetails', JSON.stringify(objParameter));

//LPHistory
export const GetLPHistory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/LP/GetLPHistory', JSON.stringify(objParameter));

//GetBugTracker
export const GetBugTracker = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BugTracker/GetBugTracker', JSON.stringify(objParameter));
export const ResolveBug = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BugTracker/ResolveBug', JSON.stringify(objParameter));
export const GetBuyByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BugTracker/GetBuyByID', JSON.stringify(objParameter));





// Admin side End

// Frontend Side APIs
export const GetHome = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetHome', JSON.stringify(objParameter));
export const GetHomeDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetHomeDetails', JSON.stringify(objParameter));
export const GetHomeSubCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetHomeSubCategories', JSON.stringify(objParameter));
export const GetActionLocations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetLocations', JSON.stringify(objParameter));
export const GetActionLocationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/GetLocationByID', JSON.stringify(objParameter));
export const GetAdBuilderAssets = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetAdBuilderAssets', JSON.stringify(objParameter));
export const UpdateLocation_Featured_Location = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/UpdateLocation_Featured_Location', JSON.stringify(objParameter));
export const UpdateLocation_Location = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/UpdateLocation_Location', JSON.stringify(objParameter));
export const UpdateLocation_SocialAutomation_SelectAll = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/UpdateLocation_SocialAutomation_SelectAll', JSON.stringify(objParameter));
export const GetCategorySubCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetCategorySubCategories', JSON.stringify(objParameter));

// promowebsite APIs
export const GetMyPromoWebsite = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetMyPromoWebsite', JSON.stringify(objParameter));
// Cart
export const GetCart = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetCart', JSON.stringify(objParameter));
// CalculatePostageCharges
export const CalculatePostageCharges = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/CalculatePostageCharges', JSON.stringify(objParameter));

// Calendar
export const GetMyCalendar = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetMyCalendar', JSON.stringify(objParameter));
export const GeneratePostCopy = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GeneratePostCopy', JSON.stringify(objParameter));

//Download File 
export const GetDownloadFile = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/DownloadFile', JSON.stringify(objParameter));
export const GetProductAssetsForSocialAutomation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetProductAssetsForSocialAutomation', JSON.stringify(objParameter));

// ProductFreeAsset
export const GetProductFreeAssetByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Asset/GetProductFreeAssetByID', JSON.stringify(objParameter));// Asssets
export const DeleteProductFreeAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Asset/DeleteProductFreeAsset', JSON.stringify(objParameter));// Asssets
export const AddEditProductFreeAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Asset/AddEditProductFreeAsset', JSON.stringify(objParameter));// Asssets
export const GetProductFreeAsses = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Asset/GetProductFreeAsses', JSON.stringify(objParameter));// Asssets

//GlobalModuleTabs
export const GetGlobalModuleTabs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalModuleTabs', JSON.stringify(objParameter));
export const GetGlobalModuleTabForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalModuleTabForDropdown', JSON.stringify(objParameter));
export const GetGlobalModuleTabByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalModuleTabByID', JSON.stringify(objParameter));
export const DeleteGlobalModuleTab = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/DeleteGlobalModuleTab', JSON.stringify(objParameter));
export const AddEditGlobalModuleTab = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/AddEditGlobalModuleTab', JSON.stringify(objParameter));

// Media Library 
export const GetDocumentsAsLibrary = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetDocumentsAsLibrary', JSON.stringify(objParameter));
export const UpdateDocumentDescription = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/UpdateDocumentDescription', JSON.stringify(objParameter));
export const SetDocumentAsFavourite = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/SetDocumentAsFavourite', JSON.stringify(objParameter));




// Technolee ERP
// Teams
export const GetTeams = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTeams', JSON.stringify(objParameter));
export const DeleteTeam = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteTeam', JSON.stringify(objParameter));
export const GetTeamForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTeamForDropdown', JSON.stringify(objParameter));
export const GetTeamByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTeamByID', JSON.stringify(objParameter));
export const AddEditTeam = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditTeam', JSON.stringify(objParameter));

// Designations
export const GetDesignations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDesignations', JSON.stringify(objParameter));
export const GetDesignationForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDesignationForDropdown', JSON.stringify(objParameter));
export const GetDesignationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDesignationByID', JSON.stringify(objParameter));
export const DeleteDesignation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteDesignation', JSON.stringify(objParameter));
export const AddEditDesignation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditDesignation', JSON.stringify(objParameter));

// Banks
export const GetBanks = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetBanks', JSON.stringify(objParameter));
export const GetBankForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetBankForDropdown', JSON.stringify(objParameter));
export const GetBankByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetBankByID', JSON.stringify(objParameter));
export const DeleteBank = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteBank', JSON.stringify(objParameter));
export const AddEditBank = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditBank', JSON.stringify(objParameter));

// Leave Types
export const GetLeaveTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetLeaveTypes', JSON.stringify(objParameter));
export const GetLeaveTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetLeaveTypeForDropdown', JSON.stringify(objParameter));
export const GetLeaveTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetLeaveTypeByID', JSON.stringify(objParameter));
export const DeleteLeaveType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteLeaveType', JSON.stringify(objParameter));
export const AddEditLeaveType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditLeaveType', JSON.stringify(objParameter));

// Holidays
export const GetHolidays = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetHolidays', JSON.stringify(objParameter));
export const GetHolidayForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetHolidayForDropdown', JSON.stringify(objParameter));
export const GetHolidayByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetHolidayByID', JSON.stringify(objParameter));
export const DeleteHoliday = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteHoliday', JSON.stringify(objParameter));
export const AddEditHoliday = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditHoliday', JSON.stringify(objParameter));

// Announcements
export const GetAnnouncements = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetAnnouncements', JSON.stringify(objParameter));
export const GetAnnouncementByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetAnnouncementByID', JSON.stringify(objParameter));
export const DeleteAnnouncement = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteAnnouncement', JSON.stringify(objParameter));
export const AddEditAnnouncement = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditAnnouncement', JSON.stringify(objParameter));

// User
export const GetUsers = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUsers', JSON.stringify(objParameter));
export const GetUserForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserForDropdown', JSON.stringify(objParameter));
export const GetUserByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserByID', JSON.stringify(objParameter));
export const DeleteUser = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/DeleteUser', JSON.stringify(objParameter));
export const AddEditUser = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/AddEditUser', JSON.stringify(objParameter));

// Increments

export const GetUserIncrements = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserIncrements', JSON.stringify(objParameter));
export const GetUserIncrementByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserIncrementByID', JSON.stringify(objParameter));
export const DeleteUserIncrement = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/DeleteUserIncrement', JSON.stringify(objParameter));
export const AddEditUserIncrement = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/AddEditUserIncrement', JSON.stringify(objParameter));

// UserLeaves

export const GetUserLeaves = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserLeaves', JSON.stringify(objParameter));
export const GetUserLeaveByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserLeaveByID', JSON.stringify(objParameter));
export const DeleteUserLeave = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/DeleteUserLeave', JSON.stringify(objParameter));
export const AddEditUserLeave = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/AddEditUserLeave', JSON.stringify(objParameter));

//Documents
export const GetDocuments = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetDocuments', JSON.stringify(objParameter));
export const GetDocumentByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetDocumentByID', JSON.stringify(objParameter));
export const DeleteDocument = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/DeleteDocument', JSON.stringify(objParameter));
export const AddEditDocument = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/AddEditDocument', JSON.stringify(objParameter));
export const GetDocumentForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetDocumentForDropdown', JSON.stringify(objParameter));

// HRDashboard
export const HRDashboard = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + '/api/Dashboard/HRDashboard', JSON.stringify(objParameter));

// UserAllotedLeaves
export const GetUserAllotedLeaves = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserAllotedLeaves', JSON.stringify(objParameter));
export const GetUserAllotedLeaveByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserAllotedLeaveByID', JSON.stringify(objParameter));
export const DeleteUserAllotedLeave = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/DeleteUserAllotedLeave', JSON.stringify(objParameter));
export const AddEditUserAllotedLeave = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/AddEditUserAllotedLeave', JSON.stringify(objParameter));

// APILogs
export const GetAPILogs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetAPILogs', JSON.stringify(objParameter));

