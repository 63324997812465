export const Images = {
  technoleelogo:require('../../assets/images/technolee-logo.png'),
  modelCloseIcon: require('../../assets/images/gray-close-ic.svg'),
  Instagam: require('../../assets/icons/instagram.png'),
  Fail: require('../../assets/icons/fail.png'),
  eyeicon: require('../../assets/icons/eyeicon.png'),
  info: require('../../assets/icons/info.png'),
  downarrow: require('../../assets/icons/down-arrow.png'),
  cross: require('../../assets/icons/cross.png'),
  FooterBG: require('../../assets/images/footer-bg.png'),
  NavigateHome: require('../../assets/icons/home-navigation.png'),
  LeftArrowBlue: require('../../assets/icons/left-arrow-blue.png'),
  RightArrowBlue: require('../../assets/icons/right-arrow-blue.png'),
  LayerIcon: require('../../assets/icons/Layericon.png'),
  Downwardarrow: require('../../assets/icons/downwardarrow.png'),
  Viewicon: require('../../assets/icons/view_icon.png'),
  RightArrowGrey: require('../../assets/images/right-arrow-grey.svg'),
  LeftArrowGrey: require('../../assets/images/left-arrow-grey.svg'),
  Bulb: require('../../assets/icons/bulb.png'),
  Product: require('../../assets/images/product.png'),
  // Next: require('../../assets/images/next.png'),
  Profileimg: require('../../assets/images/profile_generic.png'),
  CustomPromo: require('../../assets/images/custom promo.png'),
  MacBook: require('../../assets/images/macbook.png'),
  Local: require('../../assets/images/Local.png'),
  Instagramcoloricon: require('../../assets/icons/instagramcoloricon.png'),
  Facebookcoloricon: require('../../assets/icons/facebookcoloricon.png'),
  ViewSocialPost: require('../../assets/images/ViewSocialPost.png'),
  Search: require('../../assets/icons/search.png'),
  AddSocialPost1: require('../../assets/images/AddSocialPost1.jpeg'),
  AddSocialPost2: require('../../assets/images/AddSocialPost2.jpeg'),
  AddSocialPost3: require('../../assets/images/AddSocialPost3.jpeg'),
  AddSocialPost4: require('../../assets/images/AddSocialPost4.jpeg'),
  MultiBrandcampaign: require('../../assets/images/MultiBrandcampaign.jpeg'),
  InfuseMFMultitasking: require('../../assets/images/InfuseMFMultitasking.jpeg'),
  likeicons: require('../../assets/images/likehomescreen.png'),
  // Previous: require('../../assets/images/Previousarrows.png'),
  Instagramcoloricon: require('../../assets/icons/instagramcoloricon.png'),
  Facebookcoloricon: require('../../assets/icons/facebookcoloricon.png'),
  ViewSocialPost: require('../../assets/images/ViewSocialPost.png'),
  ViewPost: require('../../assets/images/ViewPost.png'),
  // Previous: require('../../assets/images/Previousarrows.png'),
  LeftArrowGrey: require('../../assets/images/LeftArrowGray.png'),
  RightArrowGray: require('../../assets/images/RightArrowGray.png'),
  NextGrayArrows: require('../../assets/images/NextGrayArrows.png'),
  BackGrayArrows: require('../../assets/images/BackGrayArrows.png'),
  MapIcon: require('../../assets/images/google-maps.png'),
  MapDown: require('../../assets/icons/map-down-arrow.png'),
  googleverifiedicon: require('../../assets/icons/Newicons/google verified icon.png'),
  googleunverified: require('../../assets/icons/Newicons/google unverified.png'),
  ExperienceComfort: require('../../assets/images/Experience-Comfort.png'),
  Comfortyoucan: require('../../assets/images/Comfort-you-can.png'),
  FindtheLance: require('../../assets/images/Find-the-Lance.png'),
  MultiBrandcalender: require('../../assets/images/MultiBrand-calender.png'),
  ReadyToReadcalender: require('../../assets/images/ReadyToRead-calender.png'),
  LancethatKeepCalender: require('../../assets/images/LancethatKeep-Calender.jpeg'),
  // FooterBG: 'https://cobrand.api.thestaging.cc/Images/DoctorLogos/e80cb76-562-d50c-44db-b5886174241d.jpeg?v=2899591964'

  // New images

  CustomPromoPagesGraphic: require('../../assets/images/NewImages/CustomPromoPagesGraphic.png'),
  CustomPromoPagesInfoTile: require('../../assets/images/NewImages/CustomPromoPagesInfoTile.png'),
  DirectMailGraphic: require('../../assets/images/NewImages/DirectMailGraphic.png'),
  DirectMailInfoTile: require('../../assets/images/NewImages/DirectMailInfoTile.png'),
  EmailMarketingGraphic: require('../../assets/images/NewImages/EmailMarketingGraphic.png'),
  EmailMarketingInfoTile: require('../../assets/images/NewImages/EmailMarketingInfoTile.png'),
  EmailMarketingTile: require('../../assets/images/NewImages/EmailMarketingTile.png'),
  LandingPageTile: require('../../assets/images/NewImages/LandingPageTile.png'),
  LocalAdCampaignGraphic: require('../../assets/images/NewImages/LocalAdCampaignGraphic.png'),
  LocalAdCampaignInfoTile: require('../../assets/images/NewImages/LocalAdCampaignInfoTile.png'),
  LocalAdCampaigTile: require('../../assets/images/NewImages/LocalAdCampaigTile.png'),
  SocialAutomationGraphic: require('../../assets/images/NewImages/SocialAutomationGraphic.png'),
  SocialAutomationInfoTile: require('../../assets/images/NewImages/SocialAutomationInfoTile.png'),
  contactusbg: require('../../assets/images/contactusbg1.jpg'),
  SocialAutomatioTile: require('../../assets/images/NewImages/SocialAutomatioTile.png'),

  pdf: require('../../assets/icons/pdf.png'),
  zip: require('../../assets/icons/zip.png'),
  video: require('../../assets/icons/play.png'),
  csv: require('../../assets/icons/csv.png'),
  html: require('../../assets/icons/files.png'),

// Technolee ERP

Ic_Profile:require('../../assets/images/profile_generic1.png')


}
