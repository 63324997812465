//Admin Side Start

//User 
//Usres

// export class clsGetUsers {
// 	constructor() {

// 		this.FirstName = null;
// 		this.LastName = null;
// 		this.EmailAddress = null;
// 		this.MobileNo = null;
// 		this.RoleNo = null;
// 		this.IsActive = null;
// 		this.LastSyncAt = null;
// 		this.CurrentPage = 1;
// 		this.RecordPerPage = 25;
// 		this.SortExpression = null;
// 		this.SortDirection = null

// 	}
// }

// export class clsGetUserByID {
// 	constructor() {
// 		this.ID = null;
// 	}
// }

// export class clsDeleteUser {
// 	constructor() {
// 		this.ID = null;
// 	}
// }

// export class clsAddEditUser {
// 	constructor() {
// 		this.ID = null;
// 		this.FirstName = null;
// 		this.LastName = null;
// 		this.EmailAddress = null;
// 		this.MobileNo = null;
// 		this.RoleID = null;
// 		this.IsActive = null;
// 	}
// }

// export class clsGetUserForDropdown {
// 	constructor() {
// 		this.RoleNo = null;
// 	}
// }


export class clsGetNav {
	constructor() {

	}
}

//GetBugTracker

export class clsGetBugTracker {
	constructor() {



		this.IsResolved = null;
		this.BugNo = null;
		this.InnerException = null;
		this.IsNewBug = null;
		this.UserName = null;

		this.ErrorOccurredAt = null;
		this.Description = null;
		this.PageURL = null;
		this.CurrentPage = null;
		this.RecordPerPage = null;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsResolveBug {
	constructor() {
		this.ID = null;
		this.ResolutionDetails = null;
	}
}



//Acs

//Rights
export class clsGetRights {
	constructor() {
		this.Name = null;
		this.RightNo = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}
export class clsGetRightForDropdown {
	constructor() {

	}
}
export class clsAddEditRight {
	constructor() {
		this.ID = null;
		this.RightNo = null;
		this.Name = null;
		this.IsActive = null;
	}
}
export class clsDeleteRight {
	constructor() {
		this.ID = null;
	}
}
export class clsGetRightByID {
	constructor() {
		this.ID = null;
	}
}


//Roles
export class clsGetRoles {
	constructor() {
		this.Name = null;
		this.RoleNo = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}
export class clsGetRoleForDropdown {
	constructor() {

	}
}
export class clsAddEditRole {
	constructor() {
		this.ID = null;
		this.RoleNo = null;
		this.Name = null;
		this.IsActive = null;
		this.JSON = null;
	}
}
export class clsDeleteRole {
	constructor() {
		this.ID = null;
	}
}
export class clsGetRoleByID {
	constructor() {
		this.ID = null;
	}
}

//Screens
export class clsGetScreens {
	constructor() {
		this.ParentName = null;
		this.MenuPath = null;
		this.IsParent = null;
		this.IsTitle = null;
		this.IsActive = null;
		this.SequenceNo = null;
		this.Name = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}
export class clsGetScreenForDropdown {
	constructor() {

	}
}
export class clsAddEditScreen {
	constructor() {
		this.ID = null;
		this.IsTitle = null;
		this.ParentID = null;
		this.IsActive = null;
		this.MenuPath = null;
		this.IsParent = null;
		this.SequenceNo = null;
		this.Name = null;
		this.JSON = null;
	}
}
export class clsDeleteScreen {
	constructor() {
		this.ID = null;
	}
}
export class clsGetScreenByID {
	constructor() {
		this.ID = null;
	}
}
export class clsGetScreenForDropdownIsParent {
	constructor() {
		this.IsParent = null;
	}
}




//Tenant

export class clsGetTenants {
	constructor() {
		this.Name = null;
		this.CompanyName = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsAddEditTenant {
	constructor() {
		this.Name = null;
		this.CompanyName = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsDeleteTenant {
	constructor() {
		this.ID = null;

	}
}
export class clsGetTenantByID {
	constructor() {
		this.ID = null;

	}
}


//TenantSMTP
export class clsGetTenantSMTPForDropdown {

}

export class clsGetTenantSMTPByID {
	constructor() {
		this.ID = null;
	}
}

export class clsGetTenantSMTP {
	constructor() {
		this.Host = null;
		this.Port = null;
		this.IsSSL = null;
		this.UserName = null;
		this.EmailFrom = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsDeleteTenantSMTP {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditTenantSMTP {
	constructor() {
		this.ID = null;
		this.Host = null;
		this.Password = null;
		this.Port = null;
		this.IsSSL = null;
		this.UserName = null;
		this.Password = null;
		this.EmailFrom = null;
	}
}


//TenantDomain

export class clsAddEditTenantDomain {
	constructor() {
		this.ID = null;
		this.URL = null;
	}
}

export class clsDeleteTenantDomain {
	constructor() {
		this.ID = null;
	}
}

export class clsGetTenantDomainByID {
	constructor() {
		this.ID = null;
	}
}

export class clsGetTenantDomains {
	constructor() {
		this.URL = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}
export class clsGetTenantDomainForDropdown {
	constructor() {
		this.URL = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}
// Site Configuration
export class clsAddEditTenantConfiguration {
	constructor() {
		this.ID = null;
		this.PageTitle = null;
		this.LoginHeaderWelcomeText = null;
		this.LoginTitle1 = null;
		this.LoginTitle2 = null;
		this.LoginCopyrightText = null;
		this.HeaderWelcomeText = null;
		this.Title1 = null;
		this.Title2 = null;
		this.CopyrightText = null;
		this.AllLocationDescriptionText = null;
		this.AllLocationInstructionText = null;
		this.ShowRegister = null;
		this.ShowHomeIcon = null;
		this.Theme = null;
		this.RegistrationTitle = null;
		this.RegistrationSubTitle = null;
		this.RegistrationMessage = null;
	}
}

export class clsGetTenantConfigurationByID {
	constructor() {
		this.ID = null;
	}
}


//Communications

//Communications

export class clsGetCommunications {
	constructor() {

		this.EmailSubject = null;
		this.ContactName = null;
		this.EmailAddress = null;
		this.Type = null;
		this.NotificationAt = null;
		this.IsRead = null;
		this.ReadAt = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;


	}
}


//CommunicationTemplate

export class clsDownloadCommunicationTemplate {
	constructor() {
		this.ID = null;
	}
}
export class clsAddEditCommunicationConfiguration {
	constructor() {
		this.ID = null;
		this.CommunicationTemplateID = null;
		this.TradeTeamID = null;
		this.UserLevelIDs = null;
		this.DesignationIDs = null;
		this.UserIDs = null;
		this.RoleIDs = null;
		this.TradeTeamIDs = null;
		this.SendToSelf = null;
		this.SendToHOD = null;
		this.SendSMS = null;
		this.SendEmail = null;
		this.SendBrowserNotification = null;
		this.SendAppNotification = null;
	}
}
export class clsDeleteCommunicationConfiguration {
	constructor() {
		this.ID = null;

	}
}
export class clsGetCommunicationConfigurationByID {
	constructor() {
		this.ID = null;
	}
}
export class clsGetCommunicationConfigurations {
	constructor() {
		this.CommunicationTemplateName = null;
		this.TradeTeamName = null;
		this.UserLevelName = null;
		this.DesignationName = null;
		this.UserName = null;
		this.RoleName = null;
		this.TradeTeamNames = null;
		this.SendToSelf = null;
		this.SendToHOD = null;
		this.SendSMS = null;
		this.SendEmail = null;
		this.SendBrowserNotification = null;
		this.SendAppNotification = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsGetCommunicationConfigurationForDropdown {
	constructor() {

	}
}


//CommunicationTemplates

export class clsGetCommunicationTemplates {
	constructor() {
		this.TemplateName = null;
		this.EmailSubject = null;
		this.TypeID = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsAddEditCommunicationTemplate {
	constructor() {
		this.IsActive = null;
		this.EmailSubject = null;
		this.BrowserNotyTitle = null;
		this.ID = null;
		this.InAppTitle = null;
		this.SMSText = null;
		this.TypeModule = null;
		this.TemplateName = null;
		this.FriendlyTitle = null;
		this.PushNotyTitle = null;
		this.BrowserNotyName = null;
		this.EmailBody = null;
		this.TypeID = null;
		this.InAppNoty = null;
		this.PushNotyName = null
	}
}

export class clsDeleteCommunicationTemplate {
	constructor() {

		this.ID = null;
	}
}

export class clsGetCommunicationTemplateByID {
	constructor() {
		this.ID = null;
	}
}
export class clsGetCommunicationTemplateForDropdown {
	constructor() {
		this.IsConfigurableByAdmin = null;
	}
}



// Master

//MaterialTypes
export class clsGetMaterialTypes {
	constructor() {
		this.Name = null;
		this.Description = null;
		this.ShortCode = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsSetPassword {
	constructor() {
		this.Password = null;
		this.RequestID = null;
	}
}

export class clsResetPassword {
	constructor() {
		this.MobileNo = null;
	}
}

export class clsGetMyProfile {
	constructor() {
		this.ID = null;
	}
}
export class clsEditProfile {
	constructor() {
		this.ID = null;
		this.FirstName = null;
		this.LastName = null;
		this.EmailAddress = null;
		this.MobileNo = null;
	}
}

export class clsGetMaterialTypeForDropdown {

}

export class clsGetMaterialTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteMaterialType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditMaterialType {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.ShortCode = null;
		this.IsActive = null;
	}
}
// Properties

export class clsGetProperties {
	constructor() {
		this.Name = null;
		this.Description = null;
	}
}
export class clsGetPropertyForDropdown {

}

export class clsGetPropertyByID {
}

export class clsDeleteProperty {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditProperty {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
	}
}

// BatchType

export class clsGetBatchTypes {
	constructor() {
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetBatchTypeForDropdown {

}

export class clsGetBatchTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteBatchType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditBatchType {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
	}
}

// Manufacturers
export class clsGetManufacturers {
	constructor() {
		this.Name = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetManufacturerForDropdown {

}

export class clsGetManufacturerByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteManufacturer {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditManufacturer {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.IsActive = null;
	}
}

// TestType
export class clsGetTestTypes {
	constructor() {
		this.Name = null;
		this.Description = null;
		this.UpperLimit = null;
		this.LowerLimit = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsGetTestTypeForDropdown {

}

export class clsGetTestTypeByID {
	constructor() {
		this.ID = null;
	}
}
export class clsDeleteTestType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditTestType {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.UpperLimit = null;
		this.LowerLimit = null;
		this.IsActive = null;
	}
}

// Moldings
export class clsGetMoldings {
	constructor() {
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetMoldingForDropdown {

}

export class clsGetMoldingByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteMolding {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditMolding {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
	}
}

// PolymerTypes
export class clsGetPolymerTypes {
	constructor() {
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetPolymerTypeForDropdown {

}

export class clsGetPolymerTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeletePolymerType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditPolymerType {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.IsActive = null;
	}
}





//DocumentTypes
export class clsGetDocumentTypes {
	constructor() {
		this.Name = null;
		this.SequenceNo = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsGetDocumentTypeForDropdown {

}

export class clsGetDocumentTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteDocumentType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditDocumentType {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.SequenceNo = null;
		this.IsActive = null;
		this.CreatedByID = null;
	}
}






//BL_ST_LensTypes

export class clsGetBL_ST_LensTypes {
	constructor() {
		this.Name = null;
		this.IsDailyLens = null;
		this.SequenceNo = null;
		this.IsActive = null;
		this.CategoryName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetBL_ST_LensTypeForDropdown {
	constructor() {
		this.CategoryID = null;
	}
}

export class clsGetBL_ST_LensTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteBL_ST_LensType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditBL_ST_LensType {
	constructor() {
		this.ID = null;
		this.CategoryID = null;
		this.Name = null;
		this.IsDailyLens = null;
		this.SequenceNo = null;
		this.IsActive = null;
		this.CreatedByID = null;
	}
}


//MaterialPriceChangeHistory
export class clsGetMaterialPriceChangeHistory {
	constructor() {
		this.FromPrice = null;
		this.ToPrice = null;
		this.ChangedAt = null;
		this.ChangedByName = null;
		this.MaterialName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}


//MaterialTypeTestTypes
export class clsGetMaterialTypeTestTypes {
	constructor() {
		this.SequenceNo = null;
		this.MaterialTypeName = null;
		this.TestTypeName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetMaterialTypeTestTypeForDropdown {
	constructor() {
		this.MaterialTypeID = null;
	}
}

export class clsGetMaterialTypeTestTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteMaterialTypeTestType {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditMaterialTypeTestType {
	constructor() {
		this.ID = null;
		this.MaterialTypeID = null;
		this.TestTypeID = null;
		this.SequenceNo = null;
	}
}



//Materials

export class clsGetMaterials {
	constructor() {
		this.Name = null;
		this.Code = null;
		this.SPG = null;
		this.Price = null;
		this.SDSLink = null;
		this.TDSLink = null;
		this.IsActive = null;
		this.ManufacturerName = null;
		this.MaterialTypeName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}


export class clsGetMaterialForDropdown {

}

export class clsGetMaterialByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteMaterial {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditMaterial {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.Code = null;
		this.MaterialTypeID = null;
		this.SPG = null;
		this.Price = null;
		this.ManufacturerID = null;
		this.SDSLink = null;
		this.TDSLink = null;
		this.Comments = null;
		this.IsActive = null;
	}
}



//MaterialInspection

export class clsAddEditMaterialInspection {
	constructor() {
		this.ID = null;
		this.MaterialID = null;
		this.JSON = null;
	}
}

export class clsDeleteMaterialInspection {
	constructor() {
		this.ID = null;
	}
}

export class clsGetMaterialInspection {
	constructor() {
		this.Result = null;
		this.TestedAt = null;
		this.TestedByName = null;
		this.MaterialName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetMaterialInspectionByID {
	constructor() {
		this.ID = null;
	}
}


















//BL_ST_LocationPricing

export class clsGetBL_ST_LocationPricingForDropdown {
}

export class clsGetBL_ST_LocationPricingByID {
	constructor() {
		this.ID = null;
	}
}

export class clsGetBL_ST_LocationPricing {
	constructor() {
		this.PricePerBox = null;
		this.LastSyncAt = null;
		this.UserName = null;
		this.LensTypeName = null;
		this.LocationName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsDeleteBL_ST_LocationPricing {
	constructor() {
		this.ID = null;
		this.LocationID = null;
		this.UserID = null;
	}
}

export class clsAddEditBL_ST_LocationPricing {
	constructor() {
		this.ID = null;
		this.LocationID = null;
		this.UserID = null;
		this.LensTypeID = null;
		this.PricePerBox = null;
	}
}


//BL_ST_Configurations
export class clsGetBL_ST_Configurations {
	constructor() {
		this.PurchaseType = null;
		this.MinimumQuantityForAnnualSupply = null;
		this.Price = null;
		this.SegmentName = null;
		this.CategoryName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetBL_ST_ConfigurationForDropdown {
	constructor() {
		this.SegmentID = null;
		this.CategoryID = null;
		this.LensTypeID = null;
	}
}

export class clsGetBL_ST_ConfigurationByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteBL_ST_Configuration {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditBL_ST_Configuration {
	constructor() {
		this.ID = null;
		this.MembershipID = null;
		this.CategoryID = null;
		this.LensTypeID = null;
		this.PurchaseType = null;
		this.MinimumQuantityForAnnualSupply = null;
		this.Price = null;
	}
}

//BL_ST_LocationPricingHistory
export class clsGetBL_ST_LocationPricingHistory {
	constructor() {
		this.PricePerBox = null;
		this.PriceAt = null;
		this.LocationName = null;
		this.LensTypeName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetBuyByID {
	constructor() {
		this.ID = null;


	}
}

// Technolee ERP
// Teams
export class clsGetTeams {
	constructor() {
		this.Name = null;
		this.IsActive = null;
		this.RoleName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsDeleteLeaveType {
	constructor() {
		this.ID = null;
	}
}

export class clsGetTeamForDropdown { }

export class clsGetTeamByID {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditTeam {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.RoleID = null;
		this.IsActive = null;
	}
}
// Designations
export class clsGetDesignations {
	constructor() {
		this.Name = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetDesignationForDropdown {

}

export class clsGetDesignationByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteDesignation {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditDesignation {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.IsActive = null;
	}
}

// Banks
export class clsGetBanks {
	constructor() {
		this.Name = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetBankForDropdown {

}

export class clsGetBankByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteBank {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditBank {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.IsActive = null;
	}
}

// Leave Types
export class clsGetLeaveTypes {
	constructor() {
		this.Name = null;
		this.IsPaidLeave = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetLeaveTypeForDropdown {

}

export class clsGetLeaveTypeByID {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditLeaveType {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.IsPaidLeave = null;
		this.IsActive = null;
	}
}
// Holidays
export class clsGetHolidays {
	constructor() {
		this.Name = null;
		this.HolidayOn = null;
		this.IsHalfDay = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetHolidayForDropdown {

}

export class clsGetHolidayByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteHoliday {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditHoliday {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.HolidayOn = null;
		this.IsHalfDay = null;
		this.IsActive = null;
	}
}

// Announcements
export class clsGetAnnouncements {
	constructor() {
		this.Name = null;
		this.StartDate = null;
		this.EndDate = null;
		this.IsForAll = null;
		this.IsActive = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetAnnouncementByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteAnnouncement {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditAnnouncement {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.Description = null;
		this.StartDate = null;
		this.EndDate = null;
		this.IsForAll = null;
		this.Roles = null;
		this.Teams = null;
		this.IsActive = null;
	}
}

// User
export class clsGetUsers {
	constructor() {
		this.FullName = null;
		this.EmailAddress = null;
		this.MobileNo = null;
		this.IsActive = null;
		this.EmployeeNo = null;
		this.JoiningDate = null;
		this.IsLocked = null;
		this.DesignationName = null;
		this.TeamName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetUserForDropdown {
	constructor() {
		this.FullName = null;
		this.MobileNo = null;
		this.EmployeeNo = null;
		this.Gender = null;
		this.TeamID = null;
		this.DesignationID = null;
	}
}

export class clsGetUserByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteUser {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditUser {
	constructor() {
		this.ID = null;
		this.FirstName = null;
		this.LastName = null;
		this.EmailAddress = null;
		this.MobileNo = null;
		this.IsActive = null;
		this.EmployeeNo = null;
		this.JoiningDate = null;
		this.ResignationDate = null;
		this.LastDate = null;
		this.Salary = null;
		this.SkypeID = null;
		this.TemporaryAddress = null;
		this.PermanentAddress = null;
		this.Gender = null;
		this.TeamID = null;
		this.DesignationID = null;
		this.IsLocked = null;
		this.BankID = null;
		this.AccountName = null;
		this.AccountNo = null;
		this.IFSC = null;
		this.RoleID = null;
	}
}



export class clsGetUserIncrements {
	constructor() {
		this.Salary = null;
		this.ApplicableFromDate = null;
		this.NextIncrementDate = null;
		this.Remarks = null;
		this.UserName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetUserIncrementByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteUserIncrement {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditUserIncrement {
	constructor() {
		this.ID = null;
		this.UserID = null;
		this.Salary = null;
		this.ApplicableFromDate = null;
		this.NextIncrementDate = null;
		this.Remarks = null;
	}
}

// UserLeaves

export class clsGetUserLeaves {
	constructor() {
		this.Reason = null;
		this.DurationTypeID = null;
		this.LeaveDate = null;
		this.Status = null;
		this.ApprovedAt = null;
		this.ApprovedByName = null;
		this.UserName = null;
		this.LeaveTypeName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetUserLeaveByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteUserLeave {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditUserLeave {
	constructor() {
		this.ID = null;
		this.UserID = null;
		this.LeaveTypeID = null;
		this.Reason = null;
		this.DurationTypeID = null;
		this.LeaveDate = null;
	}
}

//Document
export class clsGetDocuments {
	constructor() {
		this.ModuleName = null;
		this.CreatedByName = null;
		this.SizeinKB = null;
		this.DocType = null;
		this.Description = null;
		this.LastModifiedAt = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}
export class clsGetDocumentsForDropdown {

}
export class clsGetDocumentForDropdown {

}

export class clsGetDocumentsByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteDocument {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditDocument {
	constructor() {
		this.ID = null;
		this.Name = null;
		this.SequenceNo = null;
		this.IsActive = null;
		this.CreatedByID = null;
	}
}


// HRDashboard
export class clsHRDashboard {

}

// UserAllotedLeaves

export class clsGetUserAllotedLeaves {
	constructor() {
		this.Year = null;
		this.TotalLeaves = null;
		this.UsedLeaves = null;
		this.RemainingLeaves = null;
		this.LeaveTypeName = null;
		this.UserName = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}

export class clsGetUserAllotedLeaveByID {
	constructor() {
		this.ID = null;
	}
}

export class clsDeleteUserAllotedLeave {
	constructor() {
		this.ID = null;
	}
}

export class clsAddEditUserAllotedLeave {
	constructor() {
		this.ID = null;
		this.UserID = null;
		this.Year = null;
		this.LeaveTypeID = null;
		this.TotalLeaves = null;
	}
}

// APILogs
export class clsGetAPILogs {
	constructor() {
		this.MethodName = null;
		this.RequestAt = null;
		this.ResponseAt = null;
		this.Status = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;

	}
}

export class clsSetCommunicationTemplate {
	constructor() {

		this.ID = null;
		this.HTML = null;

	}
}

//UserLoginLogs
export class clsGetUserLoginLogs {
	constructor() {
		this.LoginAt = null;
		this.LoginFromIP = null;
		this.Name = null;
		this.CurrentPage = 1;
		this.RecordPerPage = 25;
		this.SortExpression = null;
		this.SortDirection = null;
	}
}