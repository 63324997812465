import Loadable from 'react-loadable';
import { Loading } from '../components';

export const ScreenLayout = Loadable({
  loader: () => import('../screens/ScreenLayout/ScreenLayout'),
  loading: Loading,
});

export const MixScreen = Loadable({
  loader: () => import('../screens/MixScreen'),
  loading: Loading,
});
export const BlankPage = Loadable({
  loader: () => import('../screens/blankPage/blankPage'),
  loading: Loading,
});
export const FormCantrol = Loadable({
  loader: () => import('../components/FormCantrol/FormCantrol'),
  loading: Loading,
});

export const Login = Loadable({
  loader: () => import('../screens/Login/Login'),
  loading: Loading,
});

export const Setpassword = Loadable({
  loader: () => import('../screens/setPassword/setPassword'),
  loading: Loading,
});


export const PrivacyPolicy = Loadable({
  loader: () => import('../screens/PrivacyPolicy/PrivacyPolicy'),
  loading: Loading,
});

export const HTMLEmailDemo = Loadable({
  loader: () => import('../screens/HTMLEmailDemo/HTMLEmailDemo'),
  loading: Loading,
});


// Admin Screen


export const UserLoginLogs = Loadable({
  loader: () => import('../screens/AdminScreen/UserLoginLogs/UserLoginLogs'),
  loading: Loading,
});
export const BugTracker = Loadable({
  loader: () => import('../screens/AdminScreen/BugTracker/BugTracker'),
  loading: Loading,
});

//ACS
export const Rights = Loadable({
  loader: () => import('../screens/AdminScreen/ACS/Rights/Rights'),
  loading: Loading,
});
export const Roles = Loadable({
  loader: () => import('../screens/AdminScreen/ACS/Roles/Roles'),
  loading: Loading,
});

export const Screens = Loadable({
  loader: () => import('../screens/AdminScreen/ACS/Screens/Screens'),
  loading: Loading,
});


//Tenant

export const TenantDomains = Loadable({
  loader: () => import('../screens/AdminScreen/Tenant/TenantDomains/TenantDomains'),
  loading: Loading,
});
export const Tenants = Loadable({
  loader: () => import('../screens/AdminScreen/Tenant/Tenants/Tenants'),
  loading: Loading,
});
export const TenantSMTP = Loadable({
  loader: () => import('../screens/AdminScreen/Tenant/TenantSMTP/TenantSMTP'),
  loading: Loading,
});
export const SiteConfigurations = Loadable({
  loader: () => import('../screens/AdminScreen/SiteConfigurations/SiteConfigurations'),
  loading: Loading,
});

//Communication
export const Communications = Loadable({
  loader: () => import('../screens/AdminScreen/Communication/Communications/Communications'),
  loading: Loading,
});

export const CommunicationTemplates = Loadable({
  loader: () => import('../screens/AdminScreen/Communication/CommunicationTemplates/CommunicationTemplates'),
  loading: Loading,
});

export const CommunicationConfigurations = Loadable({
  loader: () => import('../screens/AdminScreen/Communication/CommunicationConfigurations/CommunicationConfigurations'),
  loading: Loading,
});

//Master
export const DocumentTypes = Loadable({
  loader: () => import('../screens/AdminScreen/DocumentTypes/DocumentTypes'),
  loading: Loading,
});



// Technolee ERP 
export const Dashboard = Loadable({
  loader: () => import('../screens/Dashboard/Dashboard'),
  loading: Loading,
});
export const Teams = Loadable({
  loader: () => import('../screens/TechnoleeERP/Teams/Teams'),
  loading: Loading,
});
export const Designation = Loadable({
  loader: () => import('../screens/TechnoleeERP/Designations/Designations'),
  loading: Loading,
});
export const Banks = Loadable({
  loader: () => import('../screens/TechnoleeERP/Banks/Banks'),
  loading: Loading,
});
export const LeaveTypes = Loadable({
  loader: () => import('../screens/TechnoleeERP/LeaveTypes/LeaveTypes'),
  loading: Loading,
});
export const Holidays = Loadable({
  loader: () => import('../screens/TechnoleeERP/Holidays/Holidays'),
  loading: Loading,
});
export const Announcements = Loadable({
  loader: () => import('../screens/TechnoleeERP/Announcements/Announcements'),
  loading: Loading,
});
export const Users = Loadable({
  loader: () => import('../screens/TechnoleeERP/User/User'),
  loading: Loading,
});
export const Documents = Loadable({
  loader: () => import('../screens/TechnoleeERP/Documents/Document'),
  loading: Loading,
});
export const Increments = Loadable({
  loader: () => import('../screens/TechnoleeERP/Increments/Increments'),
  loading: Loading,
});
export const UserLeaves = Loadable({
  loader: () => import('../screens/TechnoleeERP/UserLeaves/UserLeaves'),
  loading: Loading,
});
export const UserAllotedLeaves = Loadable({
  loader: () => import('../screens/TechnoleeERP/UserAllotedLeaves/UserAllotedLeaves'),
  loading: Loading,
});
export const LoginLogs = Loadable({
  loader: () => import('../screens/TechnoleeERP/Loginlogs/Loginlogs'),
  loading: Loading,
});
export const ApiLogs = Loadable({
  loader: () => import('../screens/TechnoleeERP/APILogs/APILogs'),
  loading: Loading,
});
export const EmailLogs = Loadable({
  loader: () => import('../screens/TechnoleeERP/EmailLogs/EmailLogs'),
  loading: Loading,
});

// Attendances
export const Summary = Loadable({
  loader: () => import('../screens/Attendances/Summary/Summary'),
  loading: Loading,
});
export const ByMembers = Loadable({
  loader: () => import('../screens/Attendances/ByMembers/ByMembers'),
  loading: Loading,
});
export const ByDate = Loadable({
  loader: () => import('../screens/Attendances/ByDate/ByDate'),
  loading: Loading,
});


// Profile
export const Changepassword = Loadable({
  loader: () => import('../screens/changePassword/changePassword'),
  loading: Loading,
});
export const Userprofile = Loadable({
  loader: () => import('../screens/UserProfile/UserProfile'),
  loading: Loading,
});



