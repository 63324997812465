import React, { useEffect, useState } from 'react';
import './radiobutton.css'

export default function RadioButton({ formvalue,value, setFormValue, name, RadioOption, lable, }) {


    const handleChange = (event) => {
        setFormValue({ ...formvalue, [name]: event.target.value })
    };


    return (
        <div>
            <div className="form_field">
                <div className="field-left">
                    <label>{lable}</label>
                </div>

                <div className="field-right">
                    {RadioOption.map((val, ind) => {
                        return (
                            <div key={ind} className="custom-radio" >
                                <input type="radio" id={val.id}
                                    checked={value == val.value}
                                    value={val.value}
                                    defaultChecked
                                    onChange={handleChange}
                                />
                                <label htmlFor={val.id}>{val.value}</label>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>

    )
}
